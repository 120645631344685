<template>
	<div class="list-page-template opacity-page show">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button v-if="$auth.user().rights.procedure_crud" class="btn-tbf blue" @click="$router.push({name: 'procedure-create'})">
							<div class="icon">
								<icon-plus />
							</div>
							<span class="text" v-if="$resize && $mq.above(600)">{{ $t('procedures.new_procedure') }}</span>
						</button>

						<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
							<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
							<span class="text">{{ $t('general.filters') }}</span>
							<div class="icon-filter">
								<icon-arrow />
							</div>
						</button>
						<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
							<div class="icon">
								<icon-settings />
							</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedProcess.length">{{ $t('filters.by_processes') }}</span>
							<span class="text" v-else-if="selectedProcess.length == 1">{{ filterProcesses.find(el => el.id == selectedProcess[0]).name }}</span>
							<span class="text" v-else>{{ selectedProcess.length }} {{ $t('filters.processes_selected') }}</span>

							<div class="icon-filter" v-if="!selectedProcess.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedProcess')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchProcesses" :placeholder="$t('filters.search_processes')">
							</div>

							<template v-for="process in filteredProcesses">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedProcess', process.id)" v-bind:class="{active: selectedProcess.includes(process.id)}" :key="'filter-process-' + process.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ process.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
							</div>

							<template v-for="user in filteredUsers">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div v-if="$auth.user().rights_name.includes('master_admin') || $auth.user().rights_name.includes('admin')" class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedStatus.length">{{ $t('filters.by_status') }}</span>
							<span class="text" v-else-if="selectedStatus.length == 1">{{ filterStatus.find(el => el == selectedStatus[0])}}</span>
							<span class="text" v-else>{{ selectedStatus.length }} {{ $t('filters.status_selected') }}</span>

							<div class="icon-filter" v-if="!selectedStatus.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchStatus" :placeholder="$t('filters.search_status')">
							</div>
							
							<template v-for="status in filteredStatus">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedStatus', status)" v-bind:class="{active: selectedStatus.includes(status)}" :key="'filter-status-' + status">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ status }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedCategories.length">{{ $t('filters.by_categories') }}</span>
							<span class="text" v-else-if="selectedCategories.length == 1">{{ filterCategories.find(el => el.id == selectedCategories[0]).name }}</span>
							<span class="text" v-else>{{ selectedCategories.length }} {{ $t('filters.categories_selected') }}</span>

							<div class="icon-filter" v-if="!selectedCategories.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedCategories')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchCategories" :placeholder="$t('filters.search_categories')">
							</div>

							<template v-for="category in filteredCategories">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedCategories', category.id)" v-bind:class="{active: selectedCategories.includes(category.id)}" :key="'filter-user-' + category.id">
									<div class="checkbox" :class="`level-${category.level_depth}`" :style="`padding-left: calc(10px * ${category.level_depth});`">
										<div class="checkmark"></div><span class="text">{{ category.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(905) || $resize && $mq.between([682, 780])"/>
						<div v-else-if="$resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list  v-else/>

		<template v-if="loadedList">
			<div class="data-list list-procedures" v-if="procedures.length">
				<div class="row-space-tbf header-list" v-bind:class="{'border-t-0': extendFilters}">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-index">
							<div class="text">
								{{ $t('procedures.index') }}
							</div>
						</div>
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy === 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('procedures.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(640)" class="column-filter column-reading-time">
							<div class="text">{{ $t('procedures.reading_time') }}</div>
						</div>
						<div v-if="$resize && $mq.above(850)" class="column-filter column-entries">
							<div class="text">{{ $t('procedures.visits') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-roles">
							<div class="text">{{ $t('procedures.roles') }}</div>
						</div>
						<div v-if="$resize && $mq.above(550)" class="column-filter sortable column-last-update" @click="sortList('updated_at')" v-bind:class="[sortBy === 'updated_at' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.last_update') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div class="column-filter column-user" v-if="$resize && $mq.above(985)"></div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="(procedure, index) in procedures">
					<div class="row-space-tbf row-list-item" :key="'procedura' + procedure.id" :class="{has_update: procedure.has_update}">
						<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="column-simple-text column-index">
								<div class="text">
									{{ index + 1 }}
								</div>
							</div>
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name">
								<div class="circle-status"  :class="{active: procedure.status == 'live'}"></div>
								<div class="name" @click="showProcedure(procedure.slug)">{{ procedure.name }}</div>
							</div>
							<div v-if="$resize && $mq.above(640)" class="column-simple-text column-reading-time">
								<div class="text">{{ readingTime(procedure.steps.map(el => [el.body]).join(" ")) }}</div>
							</div>
							<div v-if="$resize && $mq.above(850)" class="column-simple-text column-entries">
								<div class="text">{{ procedure.analytics.entries }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="procedure.roles.length == 1">{{ procedure.roles[0].name }}</span>
										<span v-else-if="procedure.roles.length > 1">{{`${procedure.roles[0].name} (+${(procedure.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(role, index) in procedure.roles">{{index < procedure.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(550)" class="column-simple-text column-last-update">
								<div class="text">{{ procedure.updated_at | moment('DD MMM YYYY') }} <icon-bell class="bell-icon" v-if="procedure.has_update"/></div>
							</div>
							<div class="column-avatar column-user" v-if="$resize && $mq.above(985)">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="procedure.responsible">
									<img :src="procedure.responsible.avatar" v-if="procedure.responsible && procedure.responsible.avatar">
									<div class="user-circle" v-else>
										<icon-user />
									</div>
									<template slot="popover">
										<div class="simple-text">{{procedure.responsible.first_name + ' ' + procedure.responsible.last_name}}</div>
									</template>
								</v-popover>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+procedure.id" :id="'dropdownEdit'+procedure.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ procedure.id " :aria-labelledby="'dropdownEdit'+procedure.id">
										<div class="dropdown-item" @click="showProcedure(procedure.slug)">
											<div class="simple-text">{{$t('general.view')}}</div>
										</div>
										<div class="dropdown-item" @click="editProcedure(procedure.slug)" v-if="procedure.rights.edit">
											<div class="simple-text">{{ $t('general.edit') }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('clone', {slug: procedure.slug, type: 'procedure', from: 'procedures'})" v-if="procedure.rights.clone">
											<div class="simple-text">{{ $t('general.clone') }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'procedure', from: 'index_procedures', model: procedure})" v-if="procedure.rights.delete">
											<div class="simple-text">{{ $t('general.delete') }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+procedure.id" :id="'dropdownEdit'+procedure.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ procedure.id " :aria-labelledby="'dropdownEdit'+procedure.id">
									<div class="dropdown-item" @click="showProcedure(procedure.slug)">
											<div class="simple-text">{{$t('general.view')}}</div>
										</div>
										<div class="dropdown-item" @click="editProcedure(procedure.slug)" v-if="procedure.rights.edit">
											<div class="simple-text">{{ $t('general.edit') }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('clone', {slug: procedure.slug, type: 'procedure', from: 'procedures'})" v-if="procedure.rights.clone">
											<div class="simple-text">{{ $t('general.clone') }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'procedure', from: 'index_procedures', model: procedure})" v-if="procedure.rights.delete">
											<div class="simple-text">{{ $t('general.delete') }}</div>
										</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.procedures_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.procedure_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'procedure-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('procedures.new_procedure')}}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>

		<infinite-loading :identifier="infiniteId" @infinite="infiniteProceduresHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconBell from '../../Icons/Bell'
    import IconTrash from '../../Icons/Trash'
    import IconClose from '../../Icons/Close'
    import IconProcedures from '../../Icons/Procedures'
    import IconUser from '../../Icons/User'
    import IconEdit from '../../Icons/EditDots'
	import IconSettings from '../../Icons/Settings'
	import IconEditMobile from '../../Icons/Edit'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
            IconSearch,
            IconPlus,
            IconArrow,
            IconBell,
            IconTrash,
            IconClose,
            IconProcedures,
            IconUser,
            IconEdit,
			IconSettings,
			IconEditMobile,
            LoaderItemsList,
            LoaderHeaderList,
            InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
            	loadedList: false,
            	search_filter: '',
            	queryFiltersCount: 0,
            	extendFilters: true,
            	filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',
            	filterProcesses: [],
            	selectedProcess: [],
            	searchProcesses: '',
            	filterUsers: [],
            	selectedUsers: [],
				selectedCategories: [],
				filterCategories: [],
				searchCategories: '',
            	searchUsers: '',
				selectedStatus: [],
				searchStatus: '',
            	procedures: [],
            	sortBy: '',
            	sortDirection: 'asc',
            	page: 0,
            	infiniteId: 1
            }
        },
        async mounted(){
        	this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			
        	await this.getFilters()
        	this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('navbar.procedures');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshProceduresIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
				this.searchFilterFunct()
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        computed: {
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredProcesses(){
				return getByKeywordFilter(this.filterProcesses, this.searchProcesses)
			},
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredStatus(){
				return getByKeywordStatus(this.filterStatus, this.searchStatus)
			},
			filteredCategories(){
				return getByKeywordFilter(this.filterCategories, this.searchCategories)
			}
        },
        methods: {
			readingTime(steps){
				const words = steps.replace(/(&nbsp;|&gt;|&lt;|&amp|<br>|<br| \/>|<[^>]*>?)/gm, ' ').trim().split(/\s+/)
				const wpm = 225;
				const time = words.length / wpm
				if(moment.duration(time, 'minutes')._data.minutes > 0 && moment.duration(time, 'minutes')._data.seconds > 0) {
					return moment.duration(time, 'minutes')._data.minutes + ' ' +  this.$t('general.min') + ' ' + moment.duration(time, 'minutes')._data.seconds + ' ' + this.$t('general.sec');
				} else if(moment.duration(time, 'minutes')._data.minutes == 0 && moment.duration(time, 'minutes')._data.seconds > 0) {
					return moment.duration(time, 'minutes')._data.seconds + ' ' + this.$tc('general.sec');
				} else if(moment.duration(time, 'minutes')._data.minutes > 0 && moment.duration(time, 'minutes')._data.seconds == 0) {
					return moment.duration(time, 'minutes')._data.minutes + ' ' + this.$t('general.count_minutes');
				} else if(moment.duration(time, 'minutes')._data.minutes == 0 && moment.duration(time, 'minutes')._data.seconds == 0){
					return '1 ' + this.$t('general.sec');
				}
			},
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: { roles: true, processes: true, users: true, procedure_status: true, categories: true }})
				.then(({data}) => {
					this.filterRoles = data.data.roles
        			this.filterProcesses = data.data.processes
        			this.filterUsers = data.data.users
					this.filterStatus = data.data.procedure_status
					this.filterCategories = data.data.categories
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
        	infiniteProceduresHandler($state){
        		var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

        		axios.get(this.$auth.user().instance.id + '/procedures', {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.page++
        				this.procedures.push(...data.data)
        				setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 20){
        					$state.complete()
        				}
        			}else{
        				$state.complete()
        			}

        			this.loadedList = true
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'processes'){ paramsCall.process_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'procedure_status'){ paramsCall.status = dataQuery[key].split(',').map(String) }
					if(key == 'categories'){ paramsCall.category_ids = dataQuery[key].split(',').map(String) }
				});
			},
        	showProcedure(itemSlug){
        		this.$router.push({name: 'procedure-show', params: {slug: itemSlug}})
        	},
        	selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }

				if(filterName == 'selectedProcess' || filterName == 'all'){ dataQuery['processes'] = this.selectedProcess.toString(); }

				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }

				if(filterName == 'selectedStatus' || filterName == 'all'){ dataQuery['procedure_status'] = this.selectedStatus.toString(); }

				if(filterName == 'selectedCategories' || filterName == 'all'){ dataQuery['categories'] = this.selectedCategories.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedProcess = []
					this.selectedUsers = []
					this.selectedStatus = []
					this.selectedCategories = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'processes'){ this.selectedProcess = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'procedure_status'){ this.selectedStatus = dataQuery[key].split(',').map(Number) }
					if(key == 'categories'){ this.selectedCategories = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.searchFilterFunct()
			},
			editProcedure(procedureSlug){
				this.$router.push({ name: 'procedure-edit', params: {slug: procedureSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.procedures = []
				this.infiniteId += 1
			},
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}

	function getByKeywordStatus(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	.list-procedures{
		.row-list-item{
			&:hover, &.has_update{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-filter.sortable.column-name {
			min-width: 150px;
		}
		.column-index{
			flex: 0 0 40px 
		}
		.column-name{
			flex: 1 1 auto;
			cursor: pointer;
			display: flex;
			align-items: center;
			.circle-status{
				height: 10px;
				width: 10px;
				min-width: 10px;
				border-radius: 50%;
				background: #c5c5c5;
				border: 1px solid $secondary;
				margin-right: 7px;
				&.active{
					background: #6CE0A6;
					border: 1px solid #03AE58;
				}
			}
		}
		.column-reading-time{
			flex: 0 0 150px;
		}
		.column-roles{
			flex: 0 0 180px;
			.roles{
				display: flex;
				span{
					&:last-child{
						padding-left: 4px;
					}
				}
			}
		}
		.column-entries {
			flex: 0 0 120px;
		}
		.column-last-update {
			flex: 0 0 120px;
		}
		.column-user{
			flex: 0 0 90px;
			display: flex;
			justify-content: flex-end;
		}
		@media (max-width: 1115px) {
			.column-reading-time,
			.column-entries,
			.column-last-update{
				flex: 0 0 100px;
			}
			.column-roles{
				flex: 0 0 130px;
			}
		}
	}
	.text.roles{
		span.first{
			flex: 1;
		}
		span:last-child {
			padding: 0 4px;
			align-items: center;
			display: flex;
		}
	}
	
</style>